.sixth {
  padding: 20px 0;
  @include tablet {
    padding: 40px 0;
  }
  &--wrapper {
    z-index: 1;
    @include container;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: .2;
      z-index: -1;
      pointer-events: none;
      background: url("../img/derevushka-bg2.png") no-repeat center center;
      background-size: contain;
      transform: translateX(-35%);
    }
  }

  &__content {
    max-width: 760px;
    &-item {
      display: flex;
      row-gap: 24px;
      padding: 20px 0;
      flex-direction: column;
      border-top: 1px solid #000;
      justify-content: space-between;
      @include tablet {
        row-gap: 40px;
        flex-direction: row;
      }
      &--center {
        align-items: center;
      }
    }
    &-group {
      display: flex;
      row-gap: 12px;
      column-gap: 20px;
      flex-direction: column;
      @include tablet {
        row-gap: 12px;
      }
      h3 {
        font-size: 16px;
        font-weight: 600;
        font-family: var(--font-accent);
        @include tablet {
          font-size: 18px;
        }
        @include desktop {
          font-size: 16px;
        }
        @include wide {
          font-size: 18px;
        }
      }
      p {
        font-size: 14px;
        font-family: var(--font-accent);
        @include tablet {
          font-size: 18px;
        }
        @include desktop {
          font-size: 16px;
        }
        @include wide {
          font-size: 18px;
        }
      }
    }
  }

  &__container {
    display: flex;
    justify-content: flex-end;
    position: relative;
  }
}