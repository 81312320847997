.third {
  padding: 40px 0;
  &__block {
    width: 100%;
    margin: 0 auto;
    position: relative;
    @include tablet {
      max-width: 700px;
    }
    @include desktop {
      max-width: 996px;
    }
  }
  
  &__container {
    width: 100%;
    margin: 0 auto;
    position: relative;
    border-radius: 30px;
    overflow: hidden;
    max-width: calc(100% - 30px);
    @include tablet {
      max-width: 600px;
    }
    @include desktop {
      max-width: 900px;
    }
    &:after {
      content: '';
      display: block;
      padding-bottom: 62%;
    }
  }

  &__wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;

  }

  &__slider {
    &-item {
      position: relative;
      img {
        width: 100%;
        display: block;
        max-width: 100%;
      }
    }
    &-arrow {
      top: 0;
      bottom: 0;
      display: flex;
      user-select: none;
      align-items: center;
      justify-content: center;
      margin: auto;
      width: 36px;
      height: 36px;
      z-index: 1000;
      cursor: pointer;
      position: absolute;
      border-radius: 100%;
      background: var(--green-color);
      img {
        width: 20px;
      }
      &--prev {
        left: 0;
        img {
          transform: rotate(-180deg);
        }
      }
      &--next {
        right: 0;
        img {
          transform: rotate(0deg);
        }
      }

      &.slick-disabled {
        opacity: 0.7;
      }
    }
  }
}