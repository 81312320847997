html {
	overflow-x: hidden;
	background-color: #fff;
	scroll-behavior: smooth;
	font-family: var(--font-main);
	&.modal--opened {
		overflow: hidden;
	}
}

body {
	background: #fff;
	color: var(--text-color);
	font-family: var(--font-main);
	&.modal--opened {
		overflow: hidden;
	}
}

img {
	display: block;
}

a {
	color: var(--link-color);
}

.react-datepicker-wrapper {
	width: 100%;
}