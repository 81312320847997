.tenth {
  padding: 40px 0;
  &--wrapper {
    display: flex;
    column-gap: 40px;
    flex-direction: column;
    justify-content: space-between;
    @include container;
    @include desktop {
      flex-wrap: nowrap;
      flex-direction: row;
    }
  }

  &__title {
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 20px;
    font-family: var(--font-accent);
    @include tablet {
      font-size: 30px;
    }
  }

  &__block {
    margin-bottom: 12px;
    @include tablet {
      margin-bottom: 20px;
    }
    p {
      font-size: 16px;
      font-family: var(--font-accent);
      @include tablet {
        font-size: 18px;
      }
      a {
        font-size: 14px;
        color: var(--green-color);
        text-decoration: underline;
        @include tablet {
          font-size: 18px;
        }
        &:hover {
          text-decoration: none;
        }

        &.both {
          font-size: 18px;
          font-weight: 600;
          @include tablet {
            font-size: 20px;
          }
        }
      }
    }

    &-wa {
      width: 30px;
    }
  }

  &__icons {
    display: flex;
    margin-top: 40px;
    column-gap: 10px;
  }

  &__content {
    flex-shrink: 0;
  }
  
  &__map {
    width: 100%;
    overflow: hidden;
    position: relative;
    border-radius: 30px;
    margin-top: 24px;
    @include tablet {
      width: 700px;
      margin-top: 40px;
    }
    @include desktop {
      margin-top: 0;
    }
    &:after {
      content: '';
      display: block;
      padding-bottom: 60%;
    }
    #map {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }
}