.four {
  @include tablet {
    padding: 40px 0;
  }
  &--wrapper {
    @include container;
  }

  &__blocks {
    width: 100%;
    display: flex;
    row-gap: 20px;
    column-gap: 20px;
    margin-bottom: 20px;
    flex-direction: column;
    @include tablet {
      min-width: 460px;
      flex-direction: row;
    }
    @include desktop {
      row-gap: 30px;
      column-gap: 30px;
      margin-bottom: 30px;
    }
    @include wide {
      row-gap: 40px;
      column-gap: 40px;
      margin-bottom: 40px;
    }
    &-left {
      width: 100%;
      display: flex;
      row-gap: 20px;
      flex-shrink: 0;
      flex-direction: column;
      @include tablet {
        width: 50%;
        row-gap: 30px;
      }
      @include desktop {
        width: 460px;
        row-gap: 40px;
      }
    }

    &-right {
      width: 100%;
      @include tablet {
        width: 50%;
      }
      @include desktop {
        width: 100%;
      }
    }

    &-item {
      height: 240px;
      display: block;
      flex-shrink: 0;
      overflow: hidden;
      position: relative;
      border-radius: 30px;
      @include tablet {
        height: 240px;
      }
      @include desktop {
        height: 220px;
      }
      @include wide {
        height: 240px;
      }
      img {
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
      }

      &--big {
        height: 240px;
        @include tablet {
          height: 510px;
        }
        @include desktop {
          height: 480px;
        }
        @include wide {
          height: 520px;
        }
      }
    }

    &-text {
      display: flex;
      height: 240px;
      font-size: 14px;
      padding: 0 15px;
      overflow: hidden;
      text-align: center;
      position: relative;
      border-radius: 30px;
      background: #d9d9d9;
      align-items: center;
      justify-content: center;
      font-family: var(--font-accent);
      @include tablet {
        font-size: 16px;
      }
      @include desktop {
        height: 220px;
        font-size: 18px;
      }
      @include wide {
        height: 240px;
        font-size: 20px;
      }
      &:after {
        content: '';
        display: block;
        position: absolute;
      }
      &--image-1 {
        &:after {
          right: 5%;
          bottom: 10%;
          width: 60px;
          height: 60px;
          background: url("../img/four_icon-1.png") no-repeat center;
          background-size: contain;
          @include tablet {
            width: 60px;
            height: 60px;
            right: 2.5%;
            bottom: 5%;
          }
          @include desktop {
            width: 74px;
            height: 74px;
          }
          @include wide {
            width: 90px;
            height: 90px;
          }
        }
      }
      
      p {
        br {
          display: none;
          @include desktop {
            display: block;
          }
        }
      }
    }
  }

  &__about {
    display: flex;
    row-gap: 40px;
    column-gap: 40px;
    flex-direction: column;
    justify-content: space-between;
    @include desktop {
      height: 287px;
      flex-direction: row;
    }
    &-left {
      width: 100%;
      display: flex;
      flex-shrink: 0;
      font-size: 16px;
      padding: 24px 32px;
      align-items: center;
      border-radius: 30px;
      position: relative;
      background: var(--green-color);
      @include tablet {
        font-size: 18px;
        padding: 20px 30px 40px 20px;
      }
      @include desktop {
        width: 665px;
        font-size: 18px;
        padding: 0 30px;
      }
      @include wide {
        font-size: 20px;
      }
      &:after {
        content: '';
        display: block;
        position: absolute;
        right: 4%;
        bottom: 10%;
        width: 60px;
        height: 60px;
        background: url("../img/four_icon-2.png") no-repeat center;
        background-size: contain;
        @include tablet {
          width: 72px;
          height: 72px;
          right: 2.5%;
          bottom: 5%;
        }
        @include desktop {
          width: 74px;
          height: 74px;
        }
        @include wide {
          width: 90px;
          height: 90px;
        }
      }
      p {
        color: #fff;
        font-weight: 600;
        margin-bottom: 12px;
        font-family: var(--font-accent);
      }
      ul {
        li {
          color: #fff;
          font-size: 14px;
          font-weight: 300;
          margin-bottom: 4px;
          position: relative;
          font-family: var(--font-accent);
          padding-left: 20px;
          @include tablet {
            font-size: 16px;
          }
          @include wide {
            font-size: 18px;
          }
          &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 6px;
            width: 6px;
            height: 6px;
            margin: auto;
            background: #fff;
            border-radius: 100%;
            @include tablet {
              top: 10px;
            }
          }
        }
      }
    }
    &-right {
      width: 100%;
      display: none;
      overflow: hidden;
      border-radius: 30px;
      @include desktop {
        display: block;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}