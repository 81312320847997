@mixin wide() {
	@media (min-width: 1200px) {
		@content;
	}
}

@mixin desktop() {
	@media (min-width: 992px) {
	    @content;
    }
}

@mixin tablet () {
    @media (min-width: 768px) {
		@content;
	}
}

@mixin container () {
	padding: 0 15px;
	@media (min-width: 768px) {
		width: 100%;
		margin: 0 auto;
		padding: 0 15px;
		max-width: 100%;
	}
	@media (min-width: 992px) {
		padding: 0;
		max-width: 992px;
	}
	@media (min-width: 1200px) {
		max-width: 1200px;
	}
}