.eighth {
  padding: 40px 0;
  &--wrapper {
    @include container;
  }

  &__content {
    display: flex;
    row-gap: 40px;
    flex-direction: column;
  }
}