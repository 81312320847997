.title {
  margin-bottom: 24px;
  @include tablet {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    justify-content: space-between;
  }
  &--left {
    flex-shrink: 0;
  }

  &--right {
    text-align: left;
    margin-top: 10px;
    @include tablet {
      margin-top: 0;
      text-align: right;
    }
  }

  h1 {
    font-size: 26px;
    font-weight: 600;
    color: var(--green-color);
    font-family: var(--font-accent);
    @include tablet {
      font-size: 30px;
    }
    @include desktop {
      font-size: 34px;
    }
    @include wide {
      font-size: 40px;
    }
  }

  p {
    font-size: 16px;
    line-height: 140%;
    font-family: var(--font-accent);
    @include tablet {
      font-size: 16px;
    }
    @include desktop {
      font-size: 18px;
    }
    @include wide {
      font-size: 20px;
    }
    br {
      display: none;
      @include tablet {
        display: block;
      }
    }
  }
}