.header {
  padding: 10px 0;
  border-bottom: 1px solid #e9f1f6;
  @include tablet {
    padding: 18px 0;
  }
  @include desktop {
    padding: 30px 0;
  }
  &--wrapper {
    display: flex;
    @include container;
    align-items: center;
    justify-content: space-between;
  }

  &__logo {
    display: flex;
    column-gap: 12px;
    align-items: center;
    img {
      flex-shrink: 0;
    }
    span {
      color: #000;
      opacity: .4;
      font-size: 12px;
      @include tablet {
        font-size: 14px;
      }
    }
    p {
      font-size: 16px;
      font-weight: 600;
      @include tablet {
        font-size: 20px;
      }
      @include desktop {
        font-size: 20px;
      }
      @include wide {
        font-size: 24px;
      }
    }
  }

  &__menu {
    display: none;
    @include desktop {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &-item {
      padding: 0 20px;
      @include desktop {
        padding: 0 16px;
      }
      @include wide {
        padding: 0 20px;
      }
    }
    &-link {
      color: #000;
      font-size: 18px;
      font-weight: 400;
      position: relative;
      @include desktop {
        font-size: 16px;
      }
      @include wide {
        font-size: 18px;
      }
      &:after {
        left: 0;
        width: 0;
        bottom: -4px;
        content: '';
        height: 1px;
        background: #000;
        position: absolute;
        transition: .3s ease-in all;
      }
      &:hover {
        &:after {
          width: 100%;
          transition: .3s ease-in all;
        }
      }
    }
  }
}

.whatsapp {
  right: 30px;
  bottom: 20px;
  display: none;
  position: fixed;
  z-index: 1000000;
  @include tablet {
    display: block;
  }
}
.whatsapp-icon {
  position: relative;
  z-index: 100; }
.whatsapp-icon:hover + .whatsapp-text {
  opacity: 1;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out; }
.whatsapp-icon a {
  display: block; }
.whatsapp-icon img {
  width: 60px; }
.whatsapp-text {
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  position: absolute;
  left: -15px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 110px;
  height: 30px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 13px;
  transform: translateX(-100%);
  background: #64b161;
  color: #fff;
  border-radius: 5px; }
.whatsapp-text:before {
  content: '';
  position: absolute;
  right: -15px;
  top: 7.5px;
  border: 8px solid transparent;
  border-left: 8px solid #64b161; }

.cssload-loader {
  width: 100px;
  height: 100px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  margin: 3em;
  display: inline-block;
  position: absolute;
  background: #64b161;
  top: 50%;
  left: 50%;
  margin-left: -50px;
  margin-top: -50px;
  z-index: 99; }

.cssload-loader,
.cssload-loader:before,
.cssload-loader:after {
  animation: 1.5s infinite ease-in-out;
  -o-animation: 1.5s infinite ease-in-out;
  -ms-animation: 1.5s infinite ease-in-out;
  -webkit-animation: 1.5s infinite ease-in-out;
  -moz-animation: 1.5s infinite ease-in-out; }

.cssload-loader:before,
.cssload-loader:after {
  width: 100%;
  height: 100%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0; }

.cssload-loader {
  animation-name: cssload-loader;
  -o-animation-name: cssload-loader;
  -ms-animation-name: cssload-loader;
  -webkit-animation-name: cssload-loader;
  -moz-animation-name: cssload-loader; }

@keyframes cssload-loader {
  from {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1; }
  to {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0; } }

@-webkit-keyframes cssload-loader {
  from {
    -webkit-transform: scale(0);
    opacity: 1; }
  to {
    -webkit-transform: scale(1);
    opacity: 0; } }


.wa {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  color: #fff;
  z-index: 999;
  width: 95%;
  height: 50px;
  display: flex;
  margin: 0 auto;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background: var(--green-color);
  @include tablet {
    width: 464px;
    height: 60px;
    font-size: 18px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
  }
  img {
    width: 40px;
    @include tablet {
      width: 45px;
    }
  }
}