.first {
  padding-top: 20px;
  &--wrapper {
    display: flex;
    row-gap: 30px;
    margin:  0 auto;
    padding: 0 15px;
    max-width: 930px;
    flex-direction: column;
  }
  &__title {
    h1 {
      font-size: 56px;
      font-weight: 700;
      line-height: 100%;
      color: var(--green-color);
      font-family: var(--font-accent);
      @include tablet {
        font-size: 100px;
      }
      @include desktop {
        font-size: 120px;
      }
      @include wide {
        font-size: 140px;
      }
    }

    &-text {
      color: #1c1c1c;
      font-size: 16px;
      margin-top: 10px;
      font-weight: 400;
      font-family: var(--font-accent);
      @include tablet {
        font-size: 19px;
      }
      @include wide {
        font-size: 21px;
      }
    }

    &-notice {
      opacity: 1;
      margin-bottom: -5px;
      color: var(--green-color);
      @include tablet {
        margin-bottom: -10px;
      }
    }
  }

  &__player {
    width: 100%;
    overflow: hidden;
    max-width: 900px;
    position: relative;
    border-radius: 20px;
    &:after {
      content: '';
      display: block;
      padding-bottom: 56.25%;
    }
    iframe {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
    }
  }
}