.button {
  height: 42px;
  border: none;
  display: flex;
  padding: 0 32px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  align-items: center;
  border-radius: 12px;
  white-space: nowrap;
  justify-content: center;
  font-family: var(--font-accent);
  @include tablet {
    height: 46px;
    font-size: 16px;
    padding: 0 32px;
  }
  @include desktop {
    height: 48px;
    padding: 0 36px;
  }
  @include wide {
    height: 54px;
    font-size: 18px;
    padding: 0 42px;
  }
  &--small {
    width: 100%;
    font-size: 16px;
    font-weight: 600;
  }

  &--green {
    color: #fff;
    background: var(--green-color);
    &:hover {
      background: #5e8d57;
    }
  }
  
  &--dark {
    color: #000;
    border: 2px solid #000;
    &:hover {
      color: #fff;
      background: #000;
    }
  }

  &--dark-fill {
    color: #fff;
    background: #000;
    &:hover {
      color: #fff;
      background: #000;
    }
  }
  
  &--uppercase {
    text-transform: uppercase;
  }
}

