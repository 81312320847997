.fifth {
  padding: 40px 0;
  &--wrapper {
    @include container;
  }

  &__content {
    display: flex;
    row-gap: 40px;
    flex-direction: column;
  }
  
  &__container {
    padding: 0 6px;
    position: relative;
  }

  &__slider {
    &-arrow {
      top: 0;
      bottom: 0;
      display: flex;
      user-select: none;
      align-items: center;
      justify-content: center;
      margin: auto;
      width: 36px;
      height: 36px;
      z-index: 1000;
      cursor: pointer;
      position: absolute;
      border-radius: 100%;
      background: var(--green-color);
      @include tablet {
        display: none;
      }
      img {
        width: 20px;
      }
      &--prev {
        left: -12px;
        img {
          transform: rotate(-180deg);
        }
      }
      &--next {
        right: -12px;
        img {
          transform: rotate(0deg);
        }
      }

      &.slick-disabled {
        opacity: 0.7;
      }
    }
  }
}