.second {
  padding: 20px 0;
  overflow: hidden;
  position: relative;
  @include tablet {
    padding: 40px 0;
  }
  &:after {
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: .1;
    position: absolute;
    pointer-events: none;
    transform: translateX(25%);
    background: url("../img/derevushka-bg.png") no-repeat center bottom;
    background-size: 400px;
    @include tablet {
      background-size: contain;
    }
  }
  &--wrapper {
    @include container;
  }

  &__about {
    p {
      color: #1c1c1c;
      font-size: 14px;
      line-height: 150%;
      margin-bottom: 12px;
      font-family: var(--font-accent);
      @include tablet {
        font-size: 16px;
        margin-bottom: 0;
      }
      @include desktop {
        font-size: 18px;
      }
      @include wide {
        font-size: 20px;
      }
      span {
        font-weight: 500;
        color: var(--green-color);
      }
    }
  }

  &__icons {
    display: flex;
    margin-top: 20px;
    flex-wrap: wrap;
    justify-content: space-between;
    @include tablet {
      column-gap: 80px;
      flex-wrap: nowrap;
      justify-content: flex-start;
    }
    &-item {
      display: flex;
      row-gap: 12px;
      text-align: center;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      img {
        margin: 0 auto;
        width: 50px;
        @include tablet {
          width: 70px;
        }
        @include desktop {
          width: auto;
        }
      }
      p {
        color: #1c1c1c;
        font-size: 14px;
        font-weight: 500;
        line-height: 140%;
        font-family: var(--font-accent);
        @include tablet {
          font-size: 16px;
        }
      }
    }
    &-pogoda {
      width: 100%;
      display: flex;
      margin-top: 30px;
      background: #fff;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      @include tablet {
        width: auto;
        margin-top: 0;
      }
      p {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 20px;
        text-transform: uppercase;
      }
    }
  }

  &__price {
    padding: 0 24px;
    border-radius: 30px;
    background: rgba(217, 217, 217, 0.5);
    @include tablet {
      padding: 0 40px;
    }
    &-container {
      padding: 28px 0;
      border-bottom: 1px solid #919191;
    }

    &-title {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 12px;
      color: var(--green-color);
      font-family: var(--font-accent);
      @include tablet {
        font-size: 20px;
        margin-bottom: 20px;
      }
      @include desktop {
        font-size: 21px;
      }
      @include wide {
        font-size: 24px;
      }
    }

    &-item {
      color: #1c1c1c;
      font-size: 14px;
      line-height: 140%;
      margin-bottom: 4px;
      font-family: var(--font-accent);
      @include tablet {
        font-size: 16px;
      }
      @include desktop {
        font-size: 18px;
      }
      @include wide {
        font-size: 20px;
      }
      &:last-child {
        margin-bottom: 0;
      }
      span {
        font-weight: 500;
        color: var(--green-color);
      }
    }

    &-form {
      padding: 40px 0;
      p {
        color: red;
        font-size: 16px;
        margin-bottom: 18px;
        @include tablet {
          font-size: 18px;
        }
        @include desktop {
          font-size: 20px;
        }
      }
    }
  }
}

.s-price {
  padding: 40px 0 0;
}