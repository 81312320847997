.ninth {
  padding: 40px 0;
  overflow: hidden;
  position: relative;
  &:after {
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: .1;
    display: none;
    position: absolute;
    pointer-events: none;
    transform: translateX(25%);
    background: url("../img/derevushka-bg.png") no-repeat center bottom;
    background-size: contain;
    @include tablet {
      display: block;
    }
  }

  &--wrapper {
    @include container;
  }

  &__content {
    width: 100%;
    display: flex;
    row-gap: 30px;
    max-width: 500px;
    flex-direction: column;
    @include tablet {
      row-gap: 40px;
    }
    > p {
      font-size: 16px;
      font-weight: 600;
      font-family: var(--font-accent);
      @include tablet {
        font-size: 21px;
      }
      a {
        color: var(--green-color);
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  &__form {
    width: 100%;
    padding: 24px;
    background: #f1f1f1;
    border-radius: 30px;
    @include tablet {
      padding: 40px;
    }
    &-title {
      margin-bottom: 30px;
      @include tablet {
        margin-bottom: 40px;
      }
      h2 {
        font-size: 18px;
        font-weight: 600;
        font-family: var(--font-accent);
        @include tablet {
          font-size: 21px;
        }
      }
    }

    &-body {
      margin-bottom: 30px;
      @include tablet {
        margin-bottom: 40px;
      }
    }
  }
}

.form {
  &-notice {
    color: #000;
    opacity: .6;
    font-size: 14px;
  }
  &-group {
    margin-bottom: 16px;
  }
  &-label {
    display: block;
    font-size: 14px;
    margin-bottom: 6px;
    font-family: var(--font-accent);
    @include tablet {
      font-size: 16px;
    }
  }
  &-input {
    width: 100%;
    height: 50px;
    padding: 0 20px;
    font-weight: 600;
    background: #fff;
    border-radius: 20px;
    border: 1px solid #000;
    font-family: var(--font-accent);
    &::placeholder {
      font-weight: 400;
    }
    &.is-invalid {
      background: #fff3f3;
      border: 1px solid red;
    }
  }
}